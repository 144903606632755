import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

import { MDXProvider } from '@mdx-js/react'
import HRSmall from '@/components/HRSmall'
import Video from '@/components/Video'
import Centered from '@/components/Centered'
import Split from '@/components/Split'
import Email from '@/components/Email'

const footerStyle = {
    marginTop: `auto`,
    textAlign: `right`
}

const containerStyle = {
    display: `flex`,
    flexDirection: `column`,
    minHeight: `100vh`,

    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: rhythm(24),
    padding: `${rhythm(1)} ${rhythm(3 / 4)}`,
}

const shortcodes = {
    HRSmall,
    Video,
    Centered,
    Split
}

class Layout extends React.Component {
    render() {
        const { location, title, children } = this.props
        const rootPath = `${__PATH_PREFIX__}/`
        let header

        if (location.pathname === rootPath) {
            const splitTitle = title.split(' - ')

            const bannerTitle = splitTitle[0]
            const subTitle = splitTitle[1]

            header = (
                <Link style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}>
                    <h1 style={{
                            ...scale(1.5),
                            marginBottom: 0,
                            marginTop: 0,
                        }}>
                        {bannerTitle}
                        {subTitle && 
                            <h2 style={{marginTop: rhythm(1/4), borderBottom: `none`}}>{subTitle}</h2>
                        }
                    </h1>
                </Link>
            )
        } else {
            header = (
                <h3
                    style={{
                        fontFamily: `Montserrat, sans-serif`,
                        marginTop: 0,
                    }}
                >
                    <Link
                        style={{
                            boxShadow: `none`,
                            textDecoration: `none`,
                            color: `inherit`,
                        }}
                        to={`/`}
                    >
                        {title}
                    </Link>
                </h3>
            )
        }

        return (
            <MDXProvider components={shortcodes}>
                <div style={containerStyle}>
                    <header>{header}</header>
                    <main>{children}</main>
                    <footer style={footerStyle}>
                        <div><Email /></div>
                    </footer>
                </div>
            </MDXProvider>
        )
    }
}

export default Layout
