import React, { Component } from "react"

import { useStaticQuery, graphql } from "gatsby"

class EmailReveal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            revealEmail: false
        }
    }

    componentDidMount() {
        this.setState({
            revealEmail: true
        })
    }

    render () {
        const { email } = this.props

        if (this.state.revealEmail) {
            return <span>Say hi! <a href="mailto:steven@toyworlds.co">{email}</a></span>
        } else {
            return <span>Say hi! Enable JS to see my email (or send to this domain)</span>
        }

    }
}

function Email() {
    const { site } = useStaticQuery(
        graphql`
          query {
              site {
                  siteMetadata {
                      email
                  }
              }
          }
        `
    )

    return <EmailReveal email={site.siteMetadata.email} />
}

export default Email
