import React from "react"

const styleContainer = {
    display : 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
}

export default ({children}) =>
    <div style={styleContainer}>
        {children}
    </div>
