import React from 'react'

import { rhythm } from "../utils/typography"

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: rhythm(1),
    marginBottom: rhythm(1),
    maxWidth: rhythm(4)
}

export default () => <hr style={style} />
